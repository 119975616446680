import { orderBy } from "lodash"
import React, { useEffect, useState, useCallback, useMemo } from "react"
import { Sparklines, SparklinesLine } from "react-sparklines"

import { PercentageChange } from "./PercentageChange"
import overviewData from "../api-data/cases-overview.json"
import nationData from "../api-data/cases-nation.json"
import regionData from "../api-data/cases-region.json"
import utlaData from "../api-data/cases-utla.json"
import ltlaData from "../api-data/cases-ltla.json"
import { apiRequest } from "../utils/api"
import { DelayedTimeAgo } from "./DelayedTimeAgo"
import { Spinner } from "./Spinner"
import {
  faPlus,
  faMinus,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { formatDate } from "../utils/date"

const cachedData = {
  overview: overviewData,
  nation: nationData,
  region: regionData,
  utla: utlaData,
  ltla: ltlaData,
}

const Row = ({ areaType, rank, nation, row, index, subIndex, dataProp }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpanded = useCallback(() => {
    setIsExpanded(!isExpanded)
  }, [isExpanded])

  const specimenData = row[dataProp] || {}
  const pcChange = specimenData.last_week / specimenData.previous_week - 1
  const todayPer100k = row.population
    ? (row.latest_reported * 7 * 100000) / row.population
    : null

  return (
    <>
      <tr>
        <td rowSpan={isExpanded ? 2 : 1} onClick={toggleExpanded}>
          <FontAwesomeIcon icon={isExpanded ? faMinus : faPlus} />
        </td>
        {rank && subIndex && (
          <td>
            {subIndex}&nbsp;({index})
          </td>
        )}
        {rank && !subIndex && <td>{index}</td>}
        <td>{row.name}</td>
        {nation && <td>{row.region || row.nation}</td>}
        <td
          title={
            row.latest_reported_date &&
            `Reported on ${formatDate(row.latest_reported_date)}`
          }
        >
          {row.latest_reported && row.latest_reported.toLocaleString()}
        </td>
        {!nation && (
          <td
            title={
              row.latest_reported_date &&
              `Reported on ${formatDate(row.latest_reported_date)}`
            }
          >
            {todayPer100k && todayPer100k.toFixed(1)}
          </td>
        )}
        <td
          title={
            specimenData.last_week_dates &&
            `${
              specimenData.last_week
            } cases between specimen dates ${formatDate(
              specimenData.last_week_dates.start,
            )} to ${formatDate(specimenData.last_week_dates.end)}`
          }
        >
          {specimenData.last_week_per_100k &&
            specimenData.last_week_per_100k.toFixed(1)}{" "}
        </td>
        <td
          title={
            specimenData.previous_week_dates &&
            `${
              specimenData.previous_week
            } cases between specimen dates ${formatDate(
              specimenData.previous_week_dates.start,
            )} to ${formatDate(specimenData.previous_week_dates.end)}`
          }
        >
          {specimenData.previous_week_per_100k &&
            specimenData.previous_week_per_100k.toFixed(1)}{" "}
        </td>
        <td>
          <PercentageChange value={pcChange} />
        </td>
        <td style={{ width: "7.5em", height: "1em", padding: "1px" }}>
          <Sparklines data={specimenData.trend} min={0} width={150} height={40}>
            <SparklinesLine color="blue" />
          </Sparklines>
        </td>
      </tr>
      {isExpanded && (
        <tr>
          <td colSpan={6 + (rank ? 1 : 0) + (nation ? 1 : 0)}>
            <p>
              {row.latest_reported.toLocaleString()} additional cases reported{" "}
              on {formatDate(row.latest_reported_date)}
            </p>
            {specimenData.last_week_dates && (
              <p>
                {specimenData.last_week.toLocaleString()} cases from tests taken
                between {formatDate(specimenData.last_week_dates.start)} and{" "}
                {formatDate(specimenData.last_week_dates.end)}
              </p>
            )}
            {specimenData.previous_week_dates && (
              <p>
                {specimenData.previous_week.toLocaleString()} cases from tests
                taken between{" "}
                {formatDate(specimenData.previous_week_dates.start)} and{" "}
                {formatDate(specimenData.previous_week_dates.end)}
              </p>
            )}
            {row.population && (
              <p>Mid-2019 population: {row.population.toLocaleString()}</p>
            )}
            <p>
              <a
                href={`https://coronavirus.data.gov.uk/details/cases?areaType=${encodeURIComponent(
                  areaType,
                )}&areaName=${encodeURIComponent(row.name)}`}
                rel="noreferrer nofollow noopener"
              >
                View on coronavirus.data.gov.uk{" "}
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </a>
            </p>
          </td>
        </tr>
      )}
    </>
  )
}

export const AreaTable = ({ areaType, rank = false, nation = false }) => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(cachedData[areaType])
  const [selectedRegion, setSelectedRegion] = useState()
  const [dataProp, setDataProp] = useState("specimen_4")
  useEffect(() => {
    apiRequest(`/cases/${areaType}?rank=${rank}`, setLoading, setData)
  }, [areaType, rank])

  const regions = useMemo(
    () =>
      data?.data
        .map((row) => [row.region, row.nation])
        .flat()
        .filter((val, index, self) => val && self.indexOf(val) === index)
        .sort((left, right) => left.localeCompare(right)),
    [data],
  )

  const rows = useMemo(
    () =>
      rank
        ? orderBy(
            data?.data ?? [],
            [
              ({ [dataProp]: { last_week_per_100k } }) =>
                last_week_per_100k ?? -99,
              "name",
            ],
            ["desc", "asc"],
          )
        : data?.data,
    [data, rank, dataProp],
  )

  const handleRadioChange = useCallback(
    (event) => setSelectedRegion(event.target.value),
    [],
  )

  const handleDataPropChange = useCallback(
    (event) => setDataProp(event.target.value),
    [],
  )

  if (!data) return "Loading..."

  const { modified_iso, stale, cut_off_4, cut_off_3 } = data

  if (stale)
    return "The data is being updated or is inconsistent, check back later."

  let counter = 0

  return (
    <>
      <p>
        Data updated: <DelayedTimeAgo timestamp={modified_iso} />
        {loading && <Spinner />}
      </p>
      <div className="control mb-3">
        <strong className="mr-2">Specimen date cut-off: </strong>
        <label className="radio mb-1">
          <input
            type="radio"
            name={`dataProp-${areaType}`}
            value="specimen_4"
            checked={dataProp === "specimen_4"}
            onChange={handleDataPropChange}
          />{" "}
          {formatDate(cut_off_4)}
        </label>
        <label className="radio mb-1">
          <input
            type="radio"
            name={`dataProp-${areaType}`}
            value="specimen_3"
            checked={dataProp === "specimen_3"}
            onChange={handleDataPropChange}
          />{" "}
          {formatDate(cut_off_3)}
        </label>
      </div>
      {nation && (
        <div className="control mb-3">
          <strong className="mr-2">Region or nation: </strong>
          <label className="radio mb-1">
            <input
              type="radio"
              name="region"
              value=""
              checked={!selectedRegion}
              onChange={handleRadioChange}
            />{" "}
            All
          </label>
          {regions.map((region) => (
            <label className="radio" key={region}>
              <input
                type="radio"
                name="region"
                value={region}
                checked={region === selectedRegion}
                onChange={handleRadioChange}
              />{" "}
              {region}
            </label>
          ))}
        </div>
      )}
      <div className="table-container">
        <table className="table is-narrow is-fullwidth is-bordered is-striped mt-2">
          <tbody>
            <tr>
              <th />
              {rank && <th>Rank</th>}
              <th>Name</th>
              {nation && <th>Nation or region</th>}
              <th>Added today</th>
              {!nation && <th>Added today per 700k</th>}
              <th>Latest 7 days per 100k</th>
              <th>Previous 7 days per 100k</th>
              <th>Week-on-week change</th>
              <th>5-week trend</th>
            </tr>
            {rows.map((row, index) => {
              if (
                selectedRegion &&
                !(
                  row.region === selectedRegion || row.nation === selectedRegion
                )
              )
                return null

              const key = `${row.code}-${row.name}`
              counter += 1

              return (
                <Row
                  key={key}
                  areaType={areaType}
                  row={row}
                  rank={rank}
                  nation={nation}
                  index={index + 1}
                  subIndex={selectedRegion ? counter : null}
                  dataProp={dataProp}
                />
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}

import { DateTime } from "luxon"
import LRU from "lru-cache"

const cache = new LRU({ max: 256 })

export const formatDate = (dateStr) => {
  const cachedVal = cache.get(dateStr)

  if (cachedVal) return cachedVal

  const formattedDate = DateTime.fromISO(dateStr, {
    zone: "Europe/London",
  }).toFormat("EEE, d MMMM")

  cache.set(dateStr, formattedDate)

  return formattedDate
}
